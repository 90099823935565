import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import axios from 'axios';
import Post from '../components/Post';

const Posts = () => {
    const [response, setResponse] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        axios
            .get('https://my-app.infinitywarlock.workers.dev/posts')
            .then(res => {
                setIsLoaded(true);
                setResponse(res);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <Container>
            <h1>Posts</h1>
            <Container>
                {isLoaded ? (
                    <div>
                        {response.status === 200 ? (
                            response.data.map((post, index) => {
                                return (
                                    <Post
                                        key={index}
                                        title={post.title}
                                        username={post.username}
                                        content={post.content}
                                    />
                                );
                            })
                        ) : (
                            <div>
                                <h3>Error {response.status}</h3>
                                <p>{response.data}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </Container>
        </Container>
    );
};

export default Posts;

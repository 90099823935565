import { Button, FormFeedback } from 'reactstrap';
import React, { useState } from 'react';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [titleRequired, setTitleRequired] = useState(false);
    const [username, setUsername] = useState('');
    const [usernameRequired, setUsernameRequired] = useState(false);
    const [content, setContent] = useState('');
    const [contentRequired, setContentRequired] = useState(false);
    const [response, setResponse] = useState({});

    const submit = () => {
        axios
            .post(
                'https://my-app.infinitywarlock.workers.dev/posts/',
                JSON.stringify({ title, username, content }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(res => {
                setResponse(res);
            })
            .catch(err => console.log(err));
    };

    const validateOnSubmit = ev => {
        ev.preventDefault();
        setTitleRequired(false);
        setUsernameRequired(false);
        setContentRequired(false);
        let submittable = true;
        if (title.length === 0) {
            submittable = false;
            setTitleRequired(true);
        }
        if (username.length === 0) {
            submittable = false;
            setUsernameRequired(true);
        }
        if (content.length === 0) {
            submittable = false;
            setContentRequired(true);
        }
        if (submittable) {
            submit();
        }
    };

    return (
        <Container>
            <h1>Create Post</h1>
            <Form onSubmit={validateOnSubmit}>
                <FormGroup>
                    <Label for="title">Post Title</Label>
                    <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Post Title"
                        invalid={titleRequired}
                        value={title}
                        onChange={ev => {
                            setTitle(ev.target.value);
                            setResponse({});
                        }}
                    />
                    <FormFeedback invalid>Required</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="user">Username</Label>
                    <Input
                        type="text"
                        name="user"
                        id="user"
                        placeholder="Username"
                        invalid={usernameRequired}
                        value={username}
                        onChange={ev => {
                            setUsername(ev.target.value);
                            setResponse({});
                        }}
                    />
                    <FormFeedback invalid>Required</FormFeedback>
                </FormGroup>
                <FormGroup style={{ marginBottom: 10 }}>
                    <Label for="content">Post</Label>
                    <Input
                        type="textarea"
                        name="content"
                        id="content"
                        placeholder="Your Post"
                        value={content}
                        invalid={contentRequired}
                        onChange={ev => {
                            setContent(ev.target.value);
                            setResponse({});
                        }}
                    />
                    <FormFeedback invalid>Required</FormFeedback>
                </FormGroup>
                <Button>Submit</Button>
            </Form>
            {response.data &&
                response.data !== '' &&
                (response.status === 201 ? (
                    <p>Success!</p>
                ) : (
                    <p>
                        Error {response.status} {response.data}
                    </p>
                ))}
        </Container>
    );
};

export default CreatePost;

import React from 'react';
import { Container } from 'reactstrap';

const Home = () => {
    return (
        <Container>
            <h1>A social network</h1>
            <p>
                A basic social network made for a Cloudflare internship
                application
            </p>
        </Container>
    );
};

export default Home;

import './App.css';
import {
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    NavItem,
    NavLink,
    Container,
} from 'reactstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useState } from 'react';
import Home from './routes/Home';
import CreatePost from './routes/CreatePost';
import Posts from './routes/Posts';

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Router>
            <Container>
                <Navbar color="white" light className="pl-1">
                    <NavbarBrand href="/" className="ml-auto">
                        A social network
                    </NavbarBrand>
                    <Nav className="mr-auto">
                        <NavItem>
                            <NavLink href="/posts">Posts</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/create">Create New Post</NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
                <Switch>
                    <Route path="/posts">
                        <Posts />
                    </Route>
                    <Route path="/create">
                        <CreatePost />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </Container>
        </Router>
    );
}

export default App;

import React from 'react';
import { Container, Col, Row } from 'reactstrap';

const Post = ({ title, username, content }) => {
    return (
        <Container>
            <Row>
                <Col>
                    <h3>{title}</h3>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <small>{username}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>{content}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Post;
